import React from "react"
import { Col, Row } from "react-bootstrap"

const Science4 = () => {
  return (
    <section id="science-section4" className="position-relative py-5 ">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Published studies evaluating SuperBetter</h1>
            <h5>Articles in more than a dozen scientific journals</h5>
          </Col>
          <Col md="6">
            <h4>DEPRESSION</h4>
            <p>
              In a{" "}
              <a
                href="https://www.liebertpub.com/doi/abs/10.1089/g4h.2014.0046"
                target="_blank"
                rel="noopener noreferrer"
              >
                randomized controlled trial
              </a>{" "}
              at University of Pennsylvania, playing SuperBetter for 30 days was
              associated with significant reductions in depression symptoms.
            </p>
            <p>
              In a{" "}
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0005796724000810"
                target="_blank"
                rel="noopener noreferrer"
              >
                randomized controlled trial
              </a>{" "}
              by Harvard Medical School, et al, university students who used
              SuperBetter had significant reductions in depression & anxiety at
              6-months.
            </p>
            <p>
              A{" "}
              <a
                href="https://www.frontiersin.org/articles/10.3389/fpsyg.2021.775775/full"
                target="_blank"
                rel="noopener noreferrer"
              >
                2021 clinical trial
              </a>{" "}
              found that playing the SuperBetter app can be effective in
              reducing symptoms of depression during the COVID-19 pandemic, with
              positive results maintained after 6-months.
            </p>
            <p>
              SuperBetter had the greatest effect size for reducing symptoms of
              depression among 22 apps included in the{" "}
              <a
                href="https://onlinelibrary.wiley.com/doi/full/10.1002/wps.20472"
                target="_blank"
                rel="noopener noreferrer"
              >
                first meta-analysis
              </a>{" "}
              of randomized controlled trials evaluating smartphone apps for
              depression (2017). In a follow up{" "}
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/31496095/"
                target="_blank"
                rel="noopener noreferrer"
              >
                meta-analysis
              </a>
              , SuperBetter had the #3 effect size among 54 apps (2019).
              SuperBetter also had the #1 effect size among 6 apps in a{" "}
              <a
                href="https://www.nature.com/articles/s41746-019-0188-8"
                target="_blank"
                rel="noopener noreferrer"
              >
                meta-analysis
              </a>{" "}
              evaluating trials where depression was the primary outcome.
              SuperBetter was also evaluated in a{" "}
              <a
                href="https://mhealth.jmir.org/2022/1/e29621"
                target="_blank"
                rel="noopener noreferrer"
              >
                2022 meta-analysis
              </a>
              .
            </p>
          </Col>
          <Col md="6"></Col>

          <Col md={{ span: 6, offset: 6 }}>
            <h4>ANXIETY</h4>
            <p>
              In a{" "}
              <a
                href="https://www.liebertpub.com/doi/abs/10.1089/g4h.2014.0046"
                target="_blank"
                rel="noopener noreferrer"
              >
                randomized controlled trial
              </a>{" "}
              at University of Pennsylvania, playing SuperBetter for 30 days was
              associated with significant reductions in anxiety symptoms.
            </p>
            <p>
              In a{" "}
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0005796724000810"
                target="_blank"
                rel="noopener noreferrer"
              >
                randomized controlled trial
              </a>{" "}
              by Harvard Medical School, et al, university students who used
              SuperBetter had significant reductions in depression & anxiety at
              6-months.
            </p>
            <p>
              A{" "}
              <a
                href="https://www.frontiersin.org/articles/10.3389/fpsyg.2021.775775/full"
                target="_blank"
                rel="noopener noreferrer"
              >
                2021 clinical trial
              </a>{" "}
              found that playing the SuperBetter app can be effective in
              reducing symptoms of anxiety during the COVID-19 pandemic, with
              positive results maintained after 6-months.
            </p>
            <p>
              SuperBetter had the greatest effect size for reducing symptoms of
              anxiety among 9 apps included in the{" "}
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0165032717300150"
                target="_blank"
                rel="noopener noreferrer"
              >
                first meta-analysis
              </a>{" "}
              of randomized controlled trials evaluating smartphone apps for
              anxiety (2017). In a follow up{" "}
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/31496095/"
                target="_blank"
                rel="noopener noreferrer"
              >
                meta-analysis
              </a>{" "}
              , SuperBetter had the #8 effect size among 39 apps (2019).
            </p>
          </Col>
          <Col md="6">
            <h4>CAREER SUCCESS</h4>
            <p>
              An article in the{" "}
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/03069885.2021.1940844"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-1"
              >
                British Journal of Guidance & Counseling
              </a>{" "}
              proposes that considering one’s career life from the perspective
              of a game – and in particular SuperBetter – can provide a new
              perspective for one’s career narrative and foster wellbeing,
              proactive behaviors, coping strategies & success.
            </p>
          </Col>
          <Col md="6"></Col>
          <Col md={{ span: 6, offset: 6 }}>
            <h4>CONCUSSION SYMPTOMS</h4>
            <p>
              In an{" "}
              <a
                href="https://www.tandfonline.com/doi/abs/10.1080/02699052.2017.1332388?journalCode=ibij20"
                target="_blank"
                rel="noopener noreferrer"
              >
                NIH-funded clinical trial
              </a>{" "}
              at The Ohio State University Wexner Medical Center and Cincinnati
              Children’s Hospital, teens recovering from persistent concussion
              who played the SuperBetter app for 3-6 weeks in conjunction with
              medical care had significantly greater reductions in concussion
              symptoms and increases in optimism compared to patients receiving
              standard medical care.
            </p>
          </Col>
          <Col md="6">
            <h4>BEHAVIOR CHANGE PRACTICES</h4>
            <p>
              In an{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4526993/"
                target="_blank"
                rel="noopener noreferrer"
              >
                analysis
              </a>{" "}
              of 52 exercise and physical activity game apps for inclusion of
              Health Behavior Theory (HBT) principles, SuperBetter received by
              far the highest score (76 out of 100). The average score across
              all game apps evaluated was 15.
            </p>
          </Col>
          <Col md="6"></Col>
          <Col md={{ span: 6, offset: 6 }}>
            <h4>PEDIATRIC PATIENTS</h4>
            <p>
              An article published in Journal of Pediatric Psychology used
              SuperBetter as the{" "}
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7588098/"
                target="_blank"
                rel="noopener noreferrer"
              >
                case study
              </a>{" "}
              to demonstrate the application of a practical, empirically guided
              framework to help psychologists select appropriate mental health
              apps for pediatric populations.
            </p>
          </Col>
          <Col md="6">
            <h4>CHRONIC PAIN MANAGEMENT</h4>
            <p className="mb-1">
              In an{" "}
              <a
                href="https://mhealth.jmir.org/2019/2/e13080/"
                target="_blank"
                rel="noopener noreferrer"
              >
                evaluation
              </a>{" "}
              of 19 apps for people with chronic pain SuperBetter is among three
              apps to receive the highest mark for including best practices for
              pain self-management.
            </p>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Science4
