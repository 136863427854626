import React from "react"
import { Col, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Science3 = () => {
  return (
    <section id="science-section3" className="position-relative py-5 ">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>SuperBetter increases resilience</h1>
            <p>
              Resilience is the ability to stay strong, motivated and optimistic
              even in the face of change and difficult challenges. Higher
              resilience is associated with increased academic success, higher
              graduation rates, better workplace performance, improved mental
              health and thriving in life.
            </p>
            <p>
              Participants in SuperBetter studies improved six mental, emotional
              and social measures that are scientifically linked to higher
              resilience. All of these improvements were statistically
              significant.
            </p>
          </Col>
          <Col
            md="6"
            className="mt-5 d-flex justify-content-center align-items-center"
          >
            <StaticImage
              src="../../images/science-1.svg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              placeholder="By improving
              protective factors"
              alt="By improving
              protective factors"
              className="img-fluid about-mira-01"
            />
          </Col>
          <Col
            md="6"
            className="mt-5 d-flex justify-content-center align-items-center"
          >
            <StaticImage
              src="../../images/science-2.svg"
              quality={100}
              formats={["AUTO", "WEBP"]}
              placeholder="By reducing obstacles
              to resilience"
              alt="By reducing obstacles
              to resilience"
              className="img-fluid about-mira-01"
            />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Science3
