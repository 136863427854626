import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Science1 from "../sections/the-science/the-science-1"
import Science2 from "../sections/the-science/the-science-2"
import Science3 from "../sections/the-science/the-science-3"
import Science4 from "../sections/the-science/the-science-4"
import Science5 from "../sections/the-science/the-science-5"

const TheScience = () => {
  return (
    <Layout>
      <Seo
        title="Backed by Science  "
        description="SuperBetter is evidence-based and backed by science. In published studies, SuperBetter players significantly increased their resilience and mental health."
        keywords="resilience science, mental health science "
      />
      <Science1 />
      <Science2 />
      <Science3 />
      <Science4 />
      <Science5 />
    </Layout>
  )
}

export default TheScience
