import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import Rule1 from "../../images/rule-1.svg"
import Rule2 from "../../images/rule-2.svg"
import Rule3 from "../../images/rule-3.svg"
import Rule4 from "../../images/rule-4.svg"
import Rule5 from "../../images/rule-5.svg"
import Rule6 from "../../images/rule-6.svg"
import Rule7 from "../../images/rule-7.svg"

const Science2 = () => {
  const rulesData = [
    {
      left: "Challenge yourself",
      right: "Challenge mindset",
      img: Rule1,
    },
    {
      left: "Go for an epic win",
      right: "Optimism",
      img: Rule2,
    },
    {
      left: "Seek out & complete quests",
      right: "Sense of purpose",
      img: Rule3,
    },
    {
      left: "Find & battle  the bad guys",
      right: "Mental flexibility",
      img: Rule4,
    },
    {
      left: "Collect & activate power-ups",
      right: "Emotional control",
      img: Rule5,
    },
    {
      left: "Recruit your allies",
      right: "Social connectedness",
      img: Rule6,
    },
    {
      left: "Adopt a secret identity",
      right: "Self-efficacy",
      img: Rule7,
    },
  ]
  return (
    <section id="science-section2" className="position-relative py-5 ">
      <div className="container py-3">
        <Row>
          <Col md="6" className="d-flex flex-column justify-content-center">
            <h1>The Power of Living Gamefully</h1>
            <p>
              SuperBetter is powered by the Live Gamefully® method, an
              evidence-based framework that uses the psychology of game play in
              all of life.
            </p>
            <p>
            The methodology integrates practices from game science, positive psychology and cognitive behavioral therapy.
            </p>
          </Col>
          <Col md="6" sm="12" className="d-flex justify-content-end mt-3 pb-5">
            <Row className="rules-table">
              <Col md="12">
                <Row className="my-3">
                  <Col md="6" sm="6" className="w-50">
                    <h4>SuperBetter Rules</h4>
                  </Col>
                  <Col md="6" sm="6" className="w-50">
                    <h4>Psychological Strengths</h4>
                  </Col>
                </Row>
                {rulesData.map((item, i) => (
                  <Row key={`rules-` + i} className="justify-content-center">
                    <Col md="5" sm="5" className="left-column">
                      <div className="inner-text">{item.left}</div>
                    </Col>
                    <Col md="2" sm="2" className="img-column">
                      <Image src={item.img} />
                    </Col>
                    <Col md="5" sm="5" className="right-column">
                      <div className="inner-text">{item.right}</div>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Science2
