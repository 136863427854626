
import React from "react"
import { Col, Row } from "react-bootstrap";

const Science1 = () => {
  return (
    <section id="science-section1" className="position-relative py-5 mt-5">
      <div className="container py-3">
        <Row>
          <Col
            md="12"
          >
            <h1>SuperBetter is backed by science</h1>
            <p>
            Published studies show significant benefits for resilience and mental health
            </p>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Science1;
