import { Link } from "gatsby";
import React from "react"
import { Col, Row } from "react-bootstrap"

const Science5 = () => {
  return (
    <section id="science-section5" className="position-relative py-5 ">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>Bringing the science to life</h1>
            <Link
              to="/how-it-works"
              className="how-it-work"
            > How it Works</Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Science5;
